<template>
  <div
    id="learn-class"
    :style="`background-color: ${appThemeConfig.appBgColor};`"
  >
    <learn-class-app-header ref="detailAppHead" :color="themeColor">
    </learn-class-app-header>
    <div
      id="learn-class-scroll"
      class="class-scroll"
      :style="`height:${
        isApp ? screenHeight - systemBarHeight - 85 : screenHeight - 85
      }px;`"
    >
      <div class="class-content">
        <div class="tool-bar d-flex justify-center align-center">
          <div class="progress rounded-xl mb-3">
            <span>进度：</span>
            <v-progress-linear
              :value="50"
              :color="themeColor"
              rounded
              height="7"
              class="ml-3"
            ></v-progress-linear>
          </div>
        </div>
        <div
          class="class-swiper-content d-flex align-center justify-center"
          :style="`height: calc(${contentHeight}px - 2.5rem)`"
        >
          <div
            class="class-swiper-gp"
            :style="`height: calc(${contentHeight * 0.9}px - 1rem)`"
          >
            <learn-class-swiper
              @change="swiperChange"
              ref="swiper"
              :key="cardsKey"
              class="class-swiper"
              :height="`calc(${contentHeight * 0.9}px - 3rem)`"
            >
              <div
                class="swiper-slide"
                v-for="(item, index) in classList"
                :key="index"
              >
                <class-card
                  :card-data="item"
                  :height="`calc(${contentHeight * 0.9}px - 3rem)`"
                ></class-card>
              </div>
            </learn-class-swiper>
          </div>
        </div>
      </div>
    </div>
    <learn-class-bg ref="bg" class="bg" :color="themeColor"></learn-class-bg>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate, setSystemColor } from "@/mixin/mixin";
import { isEmpty } from "@/utils/common";
import $api from "@/api/api";
import LearnClassAppHeader from "@/views/learnClass/components/learnClassAppHeader";
import LearnClassSwiper from "@/views/learnClass/components/learnClassSwiper";
import ClassCard from "@/views/learnClass/components/classCard";
import LearnClassBg from "@/views/learnClass/components/learnClassBg";

export default {
  name: "learnClassIndex",
  mixins: [setSystemColor, buttonVibrate],
  components: {
    LearnClassBg,
    ClassCard,
    LearnClassSwiper,
    LearnClassAppHeader,
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appTheme: "appTheme",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
      screenHeight: "screenHeight",
    }),
    contentHeight() {
      return this.isApp
        ? this.screenHeight - this.systemBarHeight - 85
        : this.screenHeight - 85;
    },
    themeColor() {
      return this.classList[this.swiperIndex]
        ? this.classList[this.swiperIndex].theme.bgColor
        : "#c6e0d7";
    },
  },
  data: () => ({
    cardsKey: 0,
    isLoading: true,
    isRefreshing: false,
    offLine: false,
    firstCreat: true,
    swiperIndex: 0,
    classList: [
      // {
      //   id: 3,
      //   time: "2022-03-24",
      //   title: "COVID-19 is not the number one reason for leaving the US.",
      //   subTitle: "COVID-19 不是离开美国的首要原因。",
      //   img: "https://temp.cdn.huxiaofan.com/ddcac0dff9945c5e9e2c95674edd5d36.jpeg",
      //   des: null,
      //   tag: "新闻",
      //   rate: 4,
      //   difficulty: null,
      //   type: "passage",
      //   theme: {
      //     bgColor: "#82898d",
      //     lightTextColor: "#ecf0f1",
      //     darkTextColor: "#5b5e68",
      //   },
      //   learnCount: 2,
      // },
    ],
  }),
  methods: {
    isEmpty,
    loadData() {
      this.offLine = false;
      this.isLoading = true;
      let getData = {
        id: 1,
        limit: 20,
      };
      $api
        .getPassageList(getData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.classList = res.data.result;
            this.swiperIndex = 0;
          } else {
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          this.offLine = true;
          console.log(err);
        })
        .finally(() => {
          this.$forceUpdate();
          this.cardsKey += 1;
          // this.$refs.swiper.initSwiper();
          this.isLoading = false;
          this.isRefreshing = false;
        });
    },
    swiperChange(index) {
      this.swiperIndex = index;
      this.$forceUpdate();
    },
    onRefresh() {
      // 调接口
      this.isRefreshing = false;
      this.loadData();
    },
    init() {
      this.$store.dispatch("setShowSysAppTab", false);
      this.$store.dispatch(
        "setAppSystemBarColor",
        this.appThemeConfig.systemBarColor
      );
      this.setSystemFootColor(this.appThemeConfig.footerBarColer);
      // 调接口
    },
  },
  created() {
    console.info("classtable组件被创建");
    this.init();
    this.loadData();
  },
  mounted() {
    console.info("classtable组件被挂载");
    setTimeout(() => {}, 2000);
  },
  activated() {
    console.info("classtable组件被激活");
    if (this.firstCreat) {
      this.firstCreat = false;
    } else {
      this.init();
      // this.cardsKey += 1;
      this.$refs.swiper.updateSwiper();
    }
  },
  deactivated() {
    console.info("classtable组件被暂停");
  },
};
</script>

<style lang="scss" scoped>
#learn-class {
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-align: left;
  position: relative;

  .class-scroll {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .class-content {
      width: 100%;
      max-width: 100%;
      min-height: 90vh;
      //padding: 0 0.5rem;
      word-break: break-word;

      .tool-bar {
        position: relative;
        z-index: 1;
        height: 2.5rem;
        .progress {
          background-color: #ffffff80;
          margin: 0 auto;
          width: 85%;
          display: flex;
          align-items: center;
          padding: 0 1rem;
          height: 32px;
          > span {
            flex-shrink: 0;
            font-size: 0.8rem;
          }
        }
      }

      .class-swiper-content {
        //background-color: #8ca0ff;
        .class-swiper {
        }
        .class-swiper-gp {
          width: 100%;
          min-height: 10rem;
          //background-color: #ffad68;
        }
      }
    }
  }
  .bg {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
}
::v-deep .v-chip-group {
  i {
    font-size: 0.8rem;
    opacity: 0.8;
  }
}
</style>
