<template>
  <div
    class="swiper-container"
    :style="`height: ${height}; margin-top:${top};`"
  >
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
    <div class="swiper-pagination pagination"></div>
  </div>
</template>

<script>
// core version + navigation, pagination modules:
import Swiper from "swiper/swiper-bundle.min.js";
// import Swiper and modules styles
import "swiper/swiper-bundle.min.css";

export default {
  name: "learnClassSwiper",
  title: "3D Coverflow effect",
  components: {},
  props: {
    height: {
      type: String,
      default() {
        return "60rem";
      },
    },
    top: {
      type: String,
      default() {
        return "0";
      },
    },
  },
  data() {
    return {
      swiper: null,
      swiperIndex: null,
    };
  },
  methods: {
    updateSwiper() {
      if (this.swiper) {
        console.info("滚动组件刷新");
        console.info(this.swiper);
        this.swiper.update();
      }
    },
    initSwiper() {
      console.info("滚动组件加载中");
      let swiperOptions = {
        passiveListeners: false,
        watchSlidesProgress: true, //查看slide的progress
        resistanceRatio: 0, //禁止边缘移动
        observer: true,
        direction: "vertical",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          init: (swiper) => {
            this.onSwiperInit(swiper);
          },
          resize: (swiper) => {
            swiper.update();
          },
          setTranslate: (swiper, transition) => {
            this.onSwiperTranslate(swiper, transition);
          },
          setTransition: (swiper, transition) => {
            this.onSwiperTransation(swiper, transition);
          },
          slideChange: (swiper) => {
            this.onSwiperChange(swiper);
          },
        },
      };
      this.swiper = new Swiper(".swiper-container", swiperOptions);
      this.$nextTick(() => {
        this.swiper.update();
      });
    },
    onSwiperChange(swiper) {
      this.swiperIndex = swiper.activeIndex;
      this.$emit("change", this.swiperIndex);
      console.info("滚动索引：", this.swiperIndex);
    },
    onSwiperInit(swiper) {
      let slides = swiper.slides;
      for (let i = 0; i < slides.length; i++) {
        let slide = slides.eq(i);
        slide.css("zIndex", 100 - i); //设置slide的z-index层级
      }
    },
    onSwiperTranslate(swiper) {
      let slides = swiper.slides;
      // eslint-disable-next-line no-unused-vars
      let offsetAfter = swiper.height * 0.95; //每个slide的位移值
      for (let i = 0; i < slides.length; i++) {
        let slide = slides.eq(i);
        let progress = slides[i].progress;

        if (progress <= 0) {
          //右边slide位移
          slide.transform(
            "translate3d(" +
              progress * -12 +
              "px, " +
              progress * offsetAfter * 1.088 +
              "px, 0) scale(" +
              (1 - Math.abs(progress) / 20) +
              ")"
          );

          let opacity = 1 + progress * 0.2;
          if (progress > -3) {
            opacity = 1;
          }
          // console.log(progress, opacity);
          slide.css("opacity", opacity); //最右边slide透明
          // }
          // slide.css("opacity", progress + 4.7); //最右边slide透明
        }

        if (progress > 0) {
          slide.transform("rotate(" + -progress * 5 + "deg)"); //左边slide旋转
          slide.css("opacity", 1 - progress); //左边slide透明
        }
      }
    },
    onSwiperTransation(swiper, transition) {
      // swiper, transition
      for (let i = 0; i < swiper.slides.length; i++) {
        let slide = swiper.slides.eq(i);
        slide.transition(transition);
      }
    },
  },
  mounted() {
    this.initSwiper();
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin-right: 0.3rem;
  bottom: -2rem !important;
  top: unset !important;
}
.swiper-container {
  width: 100%;
  position: relative;
}
.swiper-slide {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-left: -0.3rem;
}
.swiper-slide div {
  width: 80%;
  border-radius: 7px;
  margin: 0 auto 0rem auto;
}
.swiper-slide div img {
  width: 100%;
  display: block;
  border-radius: 7px;
}
</style>
<style>
:root {
  --swiper-theme-color: #333f25;
}
</style>
