<template>
  <div class="header" :style="`top:${isApp ? systemBarHeight + 19 : 19}px`">
    <div class="top">
      <v-btn
        class="ml-1"
        left
        width="2.7rem"
        height="2.7rem"
        fab
        small
        elevation="5"
        color="#ffffffdd"
        @click="goIndex"
      >
        <v-icon color="#1976d2">mdi-arrow-left</v-icon>
      </v-btn>
      <div class="title-text ml-4">今日阅读任务</div>
      <v-spacer></v-spacer>
      <v-chip
        small
        :color="color"
        text-color="white"
        class="mx-2"
        style="flex-shrink: 0"
      >
        {{ learnedClasses }}<strong class="ma-1">/</strong>{{ totalClasses }}
      </v-chip>
    </div>
    <div class="bottom"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "learnClassAppHeader",
  mixins: [buttonVibrate],
  props: {
    color: {
      type: String,
      default: "#c6e0d7",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    totalClasses: {
      type: Number,
      default: 3,
    },
    learnedClasses: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      systemBarHeight: "systemBarHeight",
      appThemeConfig: "appThemeConfig",
    }),
  },
  data: () => ({}),
  methods: {
    goIndex() {
      this.zzzShort();
      this.$router.replace("/");
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  z-index: 3;
  width: 100%;
  position: absolute;
  font-size: 0.7rem;
  padding: 0 1.2rem 0 1.2rem;
  font-family: sans-serif;
  .top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 0.7rem;
  }
  .bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .progress {
    }
  }
  .title-text {
    font-size: 1.1rem;
    font-weight: 700;
    color: #404040;
    letter-spacing: 0.1rem;
    text-align: right;
  }
}
</style>
