<template>
  <div class="class-card">
    <v-card
      elevation="10"
      class="mx-auto rounded-lg"
      max-width="800"
      :style="`background-color: ${cardData.theme.bgColor}; height: ${height}`"
    >
      <v-img
        height="75%"
        :src="cardData.img"
        :style="`color: ${cardData.theme.lightTextColor}`"
        :gradient="`to bottom, #00000000, ${cardData.theme.bgColor}33, ${cardData.theme.bgColor}99, ${cardData.theme.bgColor}ff`"
      >
        <div class="cover">
          <v-card-subtitle class="pt-0 time d-block" style="font-size: 0.8rem">
            <div class="d-flex align-center">
              <v-icon
                style="line-height: 1"
                small
                :color="cardData.theme.lightTextColor"
              >
                mdi-clock
              </v-icon>
              <span class="ml-1">{{ cardData.time }}</span>
            </div>
            <div class="d-flex align-center mt-1">
              <v-icon
                style="line-height: 1"
                small
                :color="cardData.theme.lightTextColor"
              >
                mdi-tag
              </v-icon>
              <span class="ml-1">{{ cardData.tag }}</span>
            </div>
          </v-card-subtitle>
          <v-card-title style="font-size: 1.2rem">
            {{ cardData.title }}
          </v-card-title>
        </div>
        <template v-slot:placeholder>
          <v-row
            :style="`background-color:${cardData.theme.bgColor}dd`"
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <div
        class="foot"
        :style="`background-color:${cardData.theme.bgColor};color: ${cardData.theme.lightTextColor}aa;`"
      >
        <v-card-text class="text--primary" style="width: unset">
          <div :style="`color: ${cardData.theme.lightTextColor}`">
            {{ cardData.subTitle }}
          </div>
        </v-card-text>

        <div class="d-flex justify-space-between align-center pb-5 px-4 action">
          <div class="d-flex left-mark">
            <div>
              已学习：{{ cardData.learnCount
              }}<span style="font-size: 0.8rem">（人）</span>
            </div>
            <div class="d-flex">
              <div>难度：</div>
              <v-rating
                background-color="grey lighten-2"
                color="warning"
                hover
                length="5"
                readonly
                x-small
                size="1"
                dense
                :value="cardData.rate"
              ></v-rating>
            </div>
          </div>
          <v-btn
            v-show="cardData.id !== 2"
            dense
            :class="screenWidth < 300 ? `px-0` : `px-5`"
            rounded
            :style="`color: ${cardData.theme.darkTextColor}`"
            @click="goPassagePage(cardData.id)"
          >
            阅读文章
          </v-btn>
          <v-btn
            v-show="cardData.id === 2"
            dense
            :class="screenWidth < 300 ? `px-0` : `px-5`"
            rounded
            color="#9ff592"
          >
            <v-icon style="margin-right: 4px" left dark>
              mdi-check-circle </v-icon
            >已学习
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { buttonVibrate } from "@/mixin/mixin";
import { mapGetters } from "vuex";

export default {
  name: "classCard",
  mixins: [buttonVibrate],
  props: {
    cardData: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String,
      default: () => "10rem",
    },
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      screenWidth: "screenWidth",
    }),
  },
  methods: {
    goPassagePage(id) {
      this.zzzShort();
      this.$router
        .push({
          path: "/reader",
          query: {
            id: id,
          },
        })
        .catch((err) => err);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__actions {
  align-self: center;
}
.class-card {
  min-height: 10rem;
  .left-mark {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transform-origin: left;
    flex-direction: column;
    font-size: 1rem;
  }
}
.cover {
  width: 100%;
  height: 100%;
  padding-top: 1.1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  .time {
    font-size: 0.9rem;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }
}
.class-card,
.other-class,
.head {
  //margin: 0 0.9rem;
  break-inside: avoid;
}
.head {
  font-size: 1.2rem;
  margin: 19px 0;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .sub-head {
    display: block;
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 300;
  }
}
.foot {
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .action {
    //margin-top: 9%;
    margin-bottom: 0.5rem;
  }
}
</style>
