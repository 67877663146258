<template>
  <div class="bg">
    <v-fade-transition>
      <div
        :key="color"
        :style="`
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        background-color: ${color};
        opacity: 0.35;
      `"
      ></div>
    </v-fade-transition>
    <v-img
      style="width: 100%; height: 102%"
      :src="
        require('@/assets/background/colorful/learnWord/long_road_lake_mountain.png')
      "
      gradient="to bottom, rgba(250,250,250,.99),rgba(250,250,250,.6),rgba(250,250,250,.5), rgba(255,255,255,0.5)"
    ></v-img>
  </div>
</template>

<script>
import { setSystemColor } from "@/mixin/mixin";

export default {
  name: "learnClassBg",
  mixins: [setSystemColor],
  props: {
    color: {
      type: String,
      default: "#c6e0d7",
    },
  },
  data() {
    return {
      timeOuts: [],
    };
  },
  methods: {
    startBg() {},
    goDark() {},
    clearTimeOut() {},
  },
  beforeDestroy() {},
  mounted() {},
  created() {
    this.setSystemStatusBarStyle(this.appThemeConfig.dark ? "light" : "dark");
  },
};
</script>

<style lang="scss" scoped></style>
